<template>
  <v-select
    data-test="select-gender"
    v-model="value"
    :style="styleSelect"
    :outlined="outlined"
    :rounded="rounded"
    :filled="filled"
    :dense="dense"
    :auto="auto"
    :items="genders"
    :label="showLabel ? label : null"
    item-text="name"
    item-value="id"
    class="v-gender-select"
    :rules="rules"
  />
</template>

<script>
export default {
  name: 'VGenderSelect',
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    auto: {
      type: Boolean,
      default: true,
    },
    styleSelect: {
      type: String,
      default: '',
    },
    gender: {
      type: [Number, String],
      default: 2,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: function() {
        return this.$t('patient.gender');
      },
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: this.gender,
    };
  },
  computed: {
    genders: function() {
      return [
        { id: 0, name: vm.$t('patient.female') },
        { id: 1, name: vm.$t('patient.male') },
        { id: 2, name: vm.$t('patient.other') },
      ];
    },
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    },
    gender() {
      const types = {
        female: 0,
        male: 1,
        unspecified: 2,
      };
      this.value = isNaN(this.gender) ? types[this.gender] : this.gender;
    },
  },
};
</script>
