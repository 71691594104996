<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" width="100%" max-width="1100" class="transparent mb-5">
        <v-toolbar flat class="transparent mb-4">
          <h1 class="headline black--text mr-3">
            <router-link to="/settings/beneficiaries">{{ $t('menu.beneficiaries') }}</router-link>
            / {{ beneficiaryId > 0 ? beneficiary.name + ' ' + beneficiary.surname : $t('beneficiaries.new') }}
          </h1>
        </v-toolbar>
        <v-card :loading="isLoading">
          <v-card-text class="details">
            <v-form ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4 lg4>
                  <v-text-field
                    v-model="beneficiary.name"
                    filled
                    dense
                    outlined
                    type="text"
                    class="mx-1"
                    :label="$t('patient.first_name') + ' *'"
                    :rules="ruleRequired"
                  />
                </v-flex>
                <v-flex xs12 sm4 lg4>
                  <v-text-field
                    v-model="beneficiary.surname"
                    filled
                    dense
                    outlined
                    type="text"
                    class="mx-1"
                    :label="$t('patient.last_name') + ' *'"
                    :rules="ruleRequired"
                  />
                </v-flex>
                <v-flex xs12 sm4 lg4>
                  <v-text-field
                    v-model="beneficiary.relationship"
                    filled
                    dense
                    outlined
                    type="text"
                    class="mx-1"
                    :label="$t('beneficiaries.relationship') + ' *'"
                    :rules="ruleRequired"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-select
                    v-model="documentType"
                    filled
                    dense
                    outlined
                    auto
                    :items="DocumentTypes"
                    :label="$t('patient.tipo_documento')"
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="beneficiary.idDocument.id"
                    filled
                    dense
                    outlined
                    type="text"
                    class="mx-1"
                    :label="$t('patient.document_id')"
                    :placeholder="beneficiary.documentIdPrev"
                    @change="verifyIdentityDocument"
                  />
                </v-flex>
                <v-flex xs6 sm6 lg6>
                  <VGenderSelect
                    :dense="true"
                    :gender="beneficiary.gender"
                    :rules="[v => v !== null || $t('common.obligatorio')]"
                    :label="`${this.$t('patient.gender')} *`"
                    @input="inputGender"
                  />
                </v-flex>
                <v-flex xs6 sm6 lg6>
                  <v-menu
                    ref="birthdateMenu"
                    v-model="birthdateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="beneficiary.birthdate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dateFormatted"
                        filled
                        dense
                        outlined
                        :rules="ruleRequired"
                        :label="$t('patient.birthdate') + ' *'"
                        append-icon="mdi-calendar"
                        readonly
                        class="text-field-date"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="date"
                      no-title
                      scrollable
                      class="input_fnacimiento"
                      first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      :min="datePickerMinDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      @input="$refs.birthdateMenu.save(date)"
                    />
                  </v-menu>
                </v-flex>
                <v-flex xs12 class="text-center">
                  <v-btn class="primary--text mr-1" depressed to="/settings/beneficiaries">{{
                    $t('common.cancel')
                  }}</v-btn>
                  <v-btn depressed class="ma-2" :disabled="isLoading" color="primary" @click="save">
                    {{ $t('common.save') }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import { beneficiariesUrl, getHeader, urlPatient, displayNameEnv } from '../../config/config';
import VGenderSelect from '../../modules/VuetifyGender/components/VGenderSelect.vue';
import { datePickerMinDate } from '@/services/dateHelper';
import { setSelectorsDocumentType } from '@/utils';

export default {
  components: { VGenderSelect },
  title: displayNameEnv + ' - ' + vm.$t('menu.beneficiaries'),
  data() {
    return {
      isLoading: false,
      beneficiaryId: vm.$route.params.id,
      beneficiary: {
        name: '',
        surname: '',
        relationship: '',
        documentIdPrev: '',
        birthdate: '',
        gender: null,
        idDocument: {
          id: '',
          type: 0,
        },
      },
      DocumentTypes: [
        { id: 1, name: 'DNI' },
        { id: 2, name: 'NIE' },
        { id: 3, name: vm.$t('patient.pasaporte') },
        { id: 4, name: vm.$t('common.otro') },
      ],
      documentType: 0,
      date: null,
      dateFormatted: null,
      birthdateMenu: false,
      valid: true,
      name: '',
      ruleRequired: [v => !!v || this.$t('common.obligatorio')],

      datePickerMinDate: datePickerMinDate(),
    };
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    birthdateMenu(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
          return;
        });
    },
    documentType() {
      this.verifyIdentityDocument();
    },
  },
  mounted() {
    if (this.beneficiaryId > 0) {
      this.loadBeneficiary();
    }
    this.DocumentTypes = setSelectorsDocumentType(this.DocumentTypes);
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    verifyIdentityDocument() {
      this.beneficiary.idDocument.type = this.documentType;
      let errorMsg = '';
      if (
        (this.beneficiary.idDocument.type === 1 || this.beneficiary.idDocument.type === 2) &&
        this.beneficiary.idDocument.id.length > 1
      ) {
        let dni = this.beneficiary.idDocument.id;
        dni = dni.replace('-', '');
        const expresion_regular =
          this.beneficiary.idDocument.type === 1
            ? /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
            : /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
        if (expresion_regular.test(dni) !== true) {
          this.beneficiary.documentIdPrev = this.beneficiary.idDocument.id;
          this.beneficiary.idDocument.id = '';
          errorMsg = this.$t('patient.dni_invalid');
        } else if (this.beneficiary.idDocument.type === 1) {
          let numero = dni.substr(0, dni.length - 1);
          const letr = dni.substr(dni.length - 1, 1);
          numero = numero % 23;
          let letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
          letra = letra.substring(numero, numero + 1);
          if (letra !== letr.toUpperCase()) {
            this.beneficiary.documentIdPrev = this.beneficiary.idDocument.id;
            this.beneficiary.idDocument.id = '';
            errorMsg = this.$t('patient.dni_invalid_letra');
          }
        }
        if (this.beneficiary.idDocument.id === '') {
          this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: errorMsg });
        }
      }
    },

    loadBeneficiary() {
      this.$scrollTo('#appRoot');
      this.isLoading = true;

      this.$http
        .get(urlPatient(beneficiariesUrl, 0, 0) + '/' + this.beneficiaryId, { headers: getHeader() })
        .then(res => {
          this.beneficiary.name = res.body.name ?? '';
          this.beneficiary.surname = res.body.surname ?? '';
          this.beneficiary.relationship = res.body.relationship ?? '';
          this.beneficiary.documentIdPrev = res.body.documentIdPrev ?? '';
          this.beneficiary.birthdate = res.body.birthdate ?? '';
          this.beneficiary.gender = res.body.gender ?? null;
          this.beneficiary.idDocument = res.body.idDocument ?? {
            id: '',
            type: 0,
          };
          const d = new Date(this.beneficiary.birthdate);
          this.date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
          this.documentType = res.body.idDocument?.type ?? 0;
        })
        .catch(err => {
          this.$log.error(err);
          // let msg = 'Ocurrió un error!';
          // if (err && err.response && err.response.data) {
          //   // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
          //   msg = err.response.data.detail;
          // }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    save() {
      this.beneficiary.language = this.$i18n.locale;
      if (this.$refs.form.validate()) {
        this.isLoading = false;
        if (this.beneficiaryId > 0) {
          this.editBeneficiary();
        } else {
          this.createBeneficiary();
        }
      }
    },

    editBeneficiary() {
      this.$http
        .put(urlPatient(beneficiariesUrl, 0, 0) + '/' + this.beneficiaryId, this.beneficiary, { headers: getHeader() })
        .then(() => {
          this.saveDone();
        })
        .catch(err => {
          this.catchError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    createBeneficiary() {
      this.$http
        .post(urlPatient(beneficiariesUrl, 0, 0), this.beneficiary, { headers: getHeader() })
        .then(() => {
          this.saveDone();
        })
        .catch(err => {
          this.catchError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    catchError(err) {
      this.$log.error(err);
      let msg = 'Ocurrió un error!';
      if (err && err.response && err.response.data) {
        msg = err.response.data.detail;
      }
      this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: msg });
    },

    saveDone() {
      const msg = {
        message: this.beneficiaryId > 0 ? this.$t('success.edit_patient') : this.$t('success.new_patient'),
        icon: 'mdi-check',
        color: 'success',
      };
      window.localStorage.setItem('msg_ehealth', JSON.stringify(msg));
      this.$router.push('/settings/beneficiaries');
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    inputGender(value) {
      this.beneficiary.gender = value;
    },
  },
};
</script>
<style>
.details {
  padding: 50px;
}
.text-field-date {
  width: 98%;
  margin-left: 4px !important;
}
</style>
